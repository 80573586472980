@font-face {
  font-family: TabletGothicNarrow;
  src: url("common/layout/fonts/TabletGothicNarrow.woff") format("woff"),
    url("common/layout/fonts/TabletGothicNarrow.ttf") format("truetype");
}

.brandColorPreview {
  font-family: TabletGothicNarrow !important;
  color: #413f4a;
}
.brandSubColorPreview {
  font-family: TabletGothicNarrow !important;
  color: #d01d2c;
}

.brandColorSemlookp {
  font-family: TabletGothicNarrow !important;
  color: #989a9c;
}
.brandSubColorSemLookp {
  font-family: TabletGothicNarrow !important;
  color: #3e3f40;
}
